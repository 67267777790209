exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-en-tsx": () => import("./../../../src/pages/about-us.en.tsx" /* webpackChunkName: "component---src-pages-about-us-en-tsx" */),
  "component---src-pages-articles-en-tsx": () => import("./../../../src/pages/articles.en.tsx" /* webpackChunkName: "component---src-pages-articles-en-tsx" */),
  "component---src-pages-artikkelit-fi-tsx": () => import("./../../../src/pages/artikkelit.fi.tsx" /* webpackChunkName: "component---src-pages-artikkelit-fi-tsx" */),
  "component---src-pages-contact-us-en-tsx": () => import("./../../../src/pages/contact-us.en.tsx" /* webpackChunkName: "component---src-pages-contact-us-en-tsx" */),
  "component---src-pages-editor-en-tsx": () => import("./../../../src/pages/editor.en.tsx" /* webpackChunkName: "component---src-pages-editor-en-tsx" */),
  "component---src-pages-editori-fi-tsx": () => import("./../../../src/pages/editori.fi.tsx" /* webpackChunkName: "component---src-pages-editori-fi-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-fi-tsx": () => import("./../../../src/pages/index.fi.tsx" /* webpackChunkName: "component---src-pages-index-fi-tsx" */),
  "component---src-pages-info-en-tsx": () => import("./../../../src/pages/info.en.tsx" /* webpackChunkName: "component---src-pages-info-en-tsx" */),
  "component---src-pages-kayttoehdot-fi-tsx": () => import("./../../../src/pages/kayttoehdot.fi.tsx" /* webpackChunkName: "component---src-pages-kayttoehdot-fi-tsx" */),
  "component---src-pages-ota-yhteytta-fi-tsx": () => import("./../../../src/pages/ota-yhteytta.fi.tsx" /* webpackChunkName: "component---src-pages-ota-yhteytta-fi-tsx" */),
  "component---src-pages-privacy-policy-en-tsx": () => import("./../../../src/pages/privacy-policy.en.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-en-tsx" */),
  "component---src-pages-saved-file-access-guide-tsx": () => import("./../../../src/pages/saved-file-access-guide.tsx" /* webpackChunkName: "component---src-pages-saved-file-access-guide-tsx" */),
  "component---src-pages-terms-of-use-en-tsx": () => import("./../../../src/pages/terms-of-use.en.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-en-tsx" */),
  "component---src-pages-tietoa-meista-fi-tsx": () => import("./../../../src/pages/tietoa-meista.fi.tsx" /* webpackChunkName: "component---src-pages-tietoa-meista-fi-tsx" */),
  "component---src-pages-tietosuojaseloste-fi-tsx": () => import("./../../../src/pages/tietosuojaseloste.fi.tsx" /* webpackChunkName: "component---src-pages-tietosuojaseloste-fi-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../../../src/templates/articleTemplate.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */)
}

