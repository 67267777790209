import "@fontsource-variable/open-sans";
import { getAnalytics, logEvent } from "firebase/analytics";
import { FirebaseApp, initializeApp } from "firebase/app";
import { GatsbyBrowser } from "gatsby";
import "./canvasToBlobPolyfill";
import "./src/global.css";

let app: FirebaseApp | undefined;

function isProdEnv() {
  return (
    process.env.GATSBY_ACTIVE_ENV === undefined &&
    process.env.NODE_ENV === "production"
  );
}

function isDoNotTrack() {
  let doNotTrack: boolean | undefined;
  if (
    (window as any).doNotTrack ||
    navigator.doNotTrack ||
    (navigator as any).msDoNotTrack
  ) {
    if (
      (window as any).doNotTrack === "1" ||
      navigator.doNotTrack === "yes" ||
      navigator.doNotTrack === "1" ||
      (navigator as any).msDoNotTrack === "1"
    ) {
      doNotTrack = true;
    } else {
      doNotTrack = false;
    }
  } else {
    doNotTrack = undefined;
  }
  return doNotTrack === undefined ? false : doNotTrack;
}

function initAnalyticsOnEvent(event: any) {
  event.currentTarget.removeEventListener(event.type, initAnalyticsOnEvent); // remove the event listener that got triggered
  initAnalytics();
}

function initAnalytics() {
  if ((window as any).analyticsInitDone) {
    return false;
  }
  (window as any).analyticsInitDone = true; // flag to ensure script does not get added to DOM more than once.

  if (isProdEnv()) {
    const analytics = getAnalytics(app);
    if (isDoNotTrack()) {
      // GA 4
      // app.automaticDataCollectionEnabled = false;
      logEvent(analytics, "do_not_track");
    }
  }
}

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  if (process.env.GATSBY_FIREBASE_API_KEY) {
    const firebaseConfig = {
      apiKey: process.env.GATSBY_FIREBASE_API_KEY,
      authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
      storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.GATSBY_FIREBASE_APP_ID,
      measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
    };

    // Initialize Firebase
    app = initializeApp(firebaseConfig);

    // Firestore initialized in App-component. (Features used only in app.)

    // https://github.com/gatsbyjs/gatsby/issues/24332#issuecomment-752373784
    document.onreadystatechange = function () {
      if (document.readyState !== "loading") {
        setTimeout(initAnalytics, 3500);
      }
    };

    document.addEventListener("scroll", initAnalyticsOnEvent);
    document.addEventListener("mousemove", initAnalyticsOnEvent);
    document.addEventListener("touchstart", initAnalyticsOnEvent);
  }
};
